import { Component, Input, OnInit } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  FormControl
} from '@angular/forms'

import * as _ from 'lodash'

import * as Texts from '../../../texts'
import { InteractActions } from '../interact.actions'
import {
  IQuest, InteractQuestState, InteractDeliveryMethod, InteractHideNames
} from '../../../app.state'
import { AjaxService } from 'app/services/ajax.service'

const LANGUAGES = [
  'English',
  'Hebrew'
]

@Component({
  selector: 'sa-app-int-quest',
  templateUrl: './interact-quest.component.html',
  styleUrls: ['./interact-quest.component.scss']
})
export class InteractQuestComponent implements OnInit {
  languages = LANGUAGES

  @Input() questionnaire = <IQuest>null
  single_participation: any = 0;
  allow_mobile_device_participation: any = 1;
  questForm: FormGroup = this.fb.group({
    'name': [''],
    'delivery_method': InteractDeliveryMethod.email,
    'sms_text': [''],
    'whatsapp_invitation_template_id': [''],
    'email_text': [''],
    'email_subject': [''],
    'hideNames': [InteractHideNames.show],
    'is_snowball_q': [''],
    'snowball_enable_autocomplete': [''],
    'is_single_participation': [''],
    'allow_mobile_device_participation': [''],
  })

  /**
   * This is set to "Next" if it's the first time (like a wizard), and is set
   *  to "Save" otherwize (edit mode)
   */
  submitButtonLabel: string

  constructor(
    private interactActions: InteractActions,
    private fb: FormBuilder,
    private ajaxService: AjaxService
  ) {
  }

  /**
   * Check the local variable against the form, update it and submit.
   */
  onSubmit = (form: FormGroup) => {

    _.each(form.controls, (v, k) => {
      if (v.dirty) { this.questionnaire[k] = v.value }
    })

    if (this.questionnaire.allow_mobile_device_participation) {
      this.questionnaire.allow_mobile_device_participation = true
    } else {
      this.questionnaire.allow_mobile_device_participation = false
    }

    this.interactActions.updateQuestionnaire(this.questionnaire)
  }

  ngOnInit() {
    this.ajaxService.isUpdatequestionValue.subscribe((value) => {
      if (value) {
        if (typeof (this.questionnaire) == "undefined" || this.questionnaire == null) {
          this.questionnaire = value;
          this.updateFormData();
        }
      }
    });
    this.updateFormData();
  }

  updateFormData() {
    /** Set up the language array */
    if (this.questionnaire) {
      // const lang = this.languages.splice(this.questionnaire.language_id - 1, 1)
      // this.languages.unshift( lang[0] )
      /** 27.11.23 Added here default value because backend doesn't back value for 'is_snowball_q' */
      if (this.questionnaire.is_snowball_q == undefined) {
        this.questionnaire.is_snowball_q = 0;
      }

      if (this.questionnaire.snowball_enable_autocomplete == undefined) {
        this.questionnaire.snowball_enable_autocomplete = 0;
      }

      if (this.questionnaire.single_participation != undefined) {
        this.single_participation = this.questionnaire.single_participation ? 1 : 0;
      }

      if (this.questionnaire.allow_mobile_device_participation != undefined) {
        this.allow_mobile_device_participation = this.questionnaire.allow_mobile_device_participation ? 1 : 0;
      }

      /** Set up the form here */
      this.questForm = this.fb.group({
        'name': ['', Validators.required],
        'delivery_method': [this.questionnaire.delivery_method],
        'sms_text': [
          this.questionnaire.sms_text,
          Validators.compose([
            Validators.required,
            Validators.maxLength(200)
          ])],
        'whatsapp_invitation_template_id': [
          this.questionnaire.whatsapp_invitation_template_id,
          Validators.compose([
            Validators.required,
            Validators.maxLength(200)
          ])],
        'email_text': [
          this.questionnaire.email_text,
          Validators.compose([
            Validators.required,
            Validators.maxLength(400)
          ])],
        'email_subject': [
          this.questionnaire.email_subject,
          Validators.compose([
            Validators.required,
            Validators.maxLength(100)
          ])],
        'hideNames': [this.questionnaire.hide_names],
        'is_snowball_q': [this.questionnaire.is_snowball_q],
        'snowball_enable_autocomplete': [this.questionnaire.snowball_enable_autocomplete],
        'is_single_participation': [this.single_participation],
        'allow_mobile_device_participation': [this.allow_mobile_device_participation]
      })

      /** Submit lable */
      this.submitButtonLabel = (this.questionnaire.state === InteractQuestState.created) ? 'NEXT' : 'SAVE'
    }
  }

  languageWasSelected = ($event) => {
    const inx = LANGUAGES.findIndex(e => e === $event) + 1
    this.questionnaire.language_id = inx
  }
  getLangsList = (lang) => {
    if (lang == 1)
      lang = 'English'
    else if (lang == 2)
      lang = 'Hebrew'
    let myClonedLangs = Object.assign([], this.languages);
    myClonedLangs = _.pull(myClonedLangs, lang)
    myClonedLangs.unshift(lang)
    return myClonedLangs
  }
}
